init_base();


$(document).ready(() => {
  init_index();
});


// function init_simple_ani() {
//   inView('.tempHide')
//     .on('enter', (el) => {
//       $(el).addClass('show');
//     })
//     .on('exit', (el) => {
//       $(el).removeClass('show');
//     })
// }

